import React, { Component, useState } from 'react'

export default class NavBar extends Component {
  render() {
    const style = {
      display: "inline",
      marginRight: "30px",
      marginLeft: "30px",
      textDecorationLine: "none",
      fontWeight: "bold",
    }
    return (
    <div style={{textAlign: "center"}}>
        <nav id="mainNav" >
            <ul>
                {this.props.items.map(page => (
                    <li style={style} key={page.name}><a href={page.url}>{page.name}</a></li>
                ))}
            </ul>
        </nav>
    </div>
    )
  }
}
